@import 'variables';
@import 'magnific-popup';

#channel_select {
	float:left;
	width:100%;
	
	.heading {
		background:#F4F4F4;
		padding:8px 12px;
		box-sizing:border-box;
		width:100%;
		margin-bottom:5px;
		cursor:pointer;
	
		&:hover {
			background:#eee;
		}
	}
}

